import { BarComponent } from '../apartment/flow/Bar';

export function ClientSellStat({
	sellClientCubicData,
	sellClientPriceData,
}: {
	sellClientCubicData: any;
	sellClientPriceData: any;
}) {
	return (
		<>
			{sellClientCubicData.length > 0 && (
				<div>
					<h3>客户统计 - 立方数</h3>

					<BarComponent
						height={sellClientCubicData.length * 20}
						{...{
							xField: 'totalCubic',
							yField: 'client',
							data: sellClientCubicData,

							xAxis: {
								label: {
									autoRotate: false,
								},
							},
							scrollbar: {
								type: 'horizontal',
							},
						}}
					/>
				</div>
			)}
			{sellClientPriceData.length > 0 && (
				<div>
					<h3>客户统计 - 金额</h3>

					<BarComponent
						height={sellClientPriceData.length * 20}
						{...{
							xAxis: {
								label: {
									autoRotate: false,
									autoHide: false,
									autoEllipsis: false,
								},
							},
							xField: 'totalPrice',
							yField: 'client',
							data: sellClientPriceData,
							scrollbar: {
								type: 'horizontal',
							},
						}}
					/>
				</div>
			)}
		</>
	);
}
